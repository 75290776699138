import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './main.scss';
import App from './App';
import { store } from './store/configureStore';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import FallbackPage from './pages/FallbackPage/FallbackPage';

// Sentry overwrites native functions that are used by Яндекс.Метрика
const DISABLED_SENTRY_INTEGRATIONS = ['FunctionToString', 'Breadcrumbs', 'TryCatch'];

const {
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_REQUIRED,
  REACT_APP_SENTRY_ENV,
} = process.env;
if (REACT_APP_SENTRY_REQUIRED === 'true') {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    attachStacktrace: true,
    environment: REACT_APP_SENTRY_ENV,
    integrations: (defaultIntegrations) =>
      defaultIntegrations.filter(
        (integration) =>
          !DISABLED_SENTRY_INTEGRATIONS.includes(integration.name),
      ),
    beforeSend(event) {
      // TODO: remove when Яндекс.Метрика starts working
      console.log(event);
      if (
        window.navigator.userAgent.includes('YaBrowser') &&
        window.navigator.userAgent.includes('iPhone') &&
        /(blur|focus)\b/.test(event.extra?.arguments[0]?.type)
      ) {
        return null;
      }
      return event;
    },
  });
}

ReactDOM.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={FallbackPage}>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </Sentry.ErrorBoundary>
  </StrictMode>,
  document.getElementById('root'),
);
